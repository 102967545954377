import { useQueryClient } from '@tanstack/react-query'
import { Outlet, createFileRoute, redirect } from '@tanstack/react-router'
import React, {
  type ReactElement,
  type ReactNode,
  useContext,
  useEffect,
  useState
} from 'react'
import { useGetUserToken } from '~/api/mutations/login'
import { useBrandInfos } from '~/api/queries/global'
import {
  useCurrentGroupId,
  useUserAcls,
  useUserPicture
} from '~/api/queries/user'
import { ConfirmGlobal } from '~/components/Global/Interactions/Confirm/ConfirmGlobal'
import { ToastType } from '~/components/Global/Interactions/Toast/Toast'
import { AsideActionWrapper } from '~/components/Layout/AsideActionWrapper/AsideActionWrapper'
import { Footer } from '~/components/Layout/Footer/Footer'
import { GlobalMenu } from '~/components/Layout/GlobalMenu/GlobalMenu'
import { getMenu } from '~/components/Layout/GlobalMenu/menu'
import { Header } from '~/components/Layout/Header/Header'
import { ApplicantModal } from '~/components/Scopes/Applicants/ApplicantModal/ApplicantModal'
import {
  globalWrapper,
  headerWrapper,
  mainWrapper,
  menuWrapper,
  pageWrapper
} from '~/pages/styles/Dashboard.css'
import type { ApplicantModalType, UserGroup } from '~/types/global'
import { toast } from '~/utils'
import {
  ApplicantModalContext,
  AsideActionContext,
  AuthContext
} from '~/utils/contexts'
import userDefaultPicture from '/avatar.svg?url'

const DashboardLayout = (): ReactElement | null => {
  const currentUser = useContext(AuthContext)
  const [asideAction, setAsideAction] = useState<ReactNode | null>(null)
  const [applicantCollection, setApplicantCollection] = useState<
    ApplicantModalType[]
  >([])
  const [applicantCurrentId, setApplicantCurrentId] = useState<number | null>(
    null
  )

  const { data: brandData } = useBrandInfos()
  const { data: userAcls } = useUserAcls(currentUser?.id ?? null)
  const refreshUserToken = useGetUserToken()
  const mainRef = React.useRef<HTMLDivElement>(null)
  const queryClient = useQueryClient()
  const {
    data: userPicture,
    isError: isErrorPicture,
    isLoading: isLoadingPicture
  } = useUserPicture(currentUser?.id ?? null)

  const currentGroupId = useCurrentGroupId()

  useEffect(() => {
    let refreshUserTokenInterval: NodeJS.Timeout
    if (currentUser) {
      refreshUserTokenInterval = setInterval(
        () => {
          refreshUserToken.mutate(
            { id: String(currentUser.id) },
            {
              onSuccess: ({ token }) => {
                localStorage.setItem('token', token)
              }
            }
          )
        },
        1000 * 60 * 5
      )
    }
    return () => {
      if (refreshUserTokenInterval) {
        clearInterval(refreshUserTokenInterval)
      }
    }
  }, [currentUser, refreshUserToken])

  if (brandData === undefined || currentUser == null) {
    return null
  }
  const legacyUrl = brandData.legacyUrl ?? null

  return (
    <>
      <div className={headerWrapper}>
        <Header
          brand={{
            name: brandData.name,
            logo: brandData.logoHeader ?? null
          }}
          superUser={currentUser?.isSuperUser}
          user={{
            id: currentUser.id,
            name: `${currentUser.firstname} ${currentUser.lastname}`,
            function: currentUser.function ?? null,
            notificationCount: currentUser.notificationUnreadCount,
            picture: isLoadingPicture
              ? undefined
              : userPicture !== undefined && !isErrorPicture
                ? userPicture.publicUrl
                : String(userDefaultPicture),
            availableGroups: currentUser.groups.map(
              (group: UserGroup): UserGroup => {
                return { ...group, active: group.id === currentGroupId }
              }
            )
          }}
          currentSection={''}
          legacyUrl={legacyUrl}
          onChangeGroup={async (group: UserGroup) => {
            localStorage.setItem('currentGroup', `${group.id}`)
            await fetch(
              `${legacyUrl ?? ''}/admin/users/change_group/${group.id}`
            )
            await queryClient.invalidateQueries({
              queryKey: ['user']
            })
          }}
        />
      </div>
      <div className={globalWrapper}>
        <div className={menuWrapper}>
          <GlobalMenu
            menu={getMenu(userAcls)}
            legacyUrl={brandData.legacyUrl ?? null}
            mainRef={mainRef}
          />
        </div>
        <ApplicantModalContext.Provider
          value={{
            applicantCollection,
            setApplicantCollection,
            applicantCurrentId,
            setApplicantCurrentId
          }}
        >
          <AsideActionContext.Provider
            value={{
              asideAction,
              setAsideAction: (node: ReactNode) => setAsideAction(node)
            }}
          >
            <div className={mainWrapper}>
              <main className={pageWrapper} ref={mainRef}>
                <Outlet />
              </main>
              <AsideActionWrapper />
              {applicantCurrentId && (
                <ApplicantModal
                  defaultApplicantId={applicantCurrentId}
                  collection={applicantCollection}
                  onClose={() => setApplicantCurrentId(null)}
                />
              )}
            </div>
          </AsideActionContext.Provider>
        </ApplicantModalContext.Provider>
      </div>
      <Footer />
      <ConfirmGlobal />
    </>
  )
}

export const Route = createFileRoute('/_dashboard')({
  beforeLoad: ({ context, location }) => {
    if (!context.currentUser) {
      localStorage.removeItem('id')
      localStorage.removeItem('token')
      localStorage.removeItem('instance')
      toast('Vous avez été déconnecté', '', ToastType.Danger)
      throw redirect({
        to: `/?redirect=${location.href}`
      })
    }
  },
  component: DashboardLayout
})
