import {
  type InfiniteData,
  type UseQueryResult,
  useInfiniteQuery,
  useQuery
} from '@tanstack/react-query'
import { fetchApiWithToken } from '~/api/utils'

export type ApplicantInfosResponse = {
  id: number
  lastname: string
  firstname: string
  email: string
  phoneMobile: string
  status: {
    id: number
    name: string
  }
  availability?: AvailabilityData
  createdAt: string
  qualifications: {
    id: number
    name: string
  }[]
}

export type ApplicantInfosLDResponse = {
  'hydra:totalItems': number
  'hydra:member': ApplicantInfosResponse[]
}

export type AvailabilityData = {
  status:
    | 'available'
    | 'unavailable'
    | 'available_in_future'
    | 'unavailable_in_future'
  date: string
}

export type ApplicantAvailabilitiesResponse = {
  availability: AvailabilityData
  applicant: string
}

export const useApplicantsAvailabilities = (
  applicantIds: number[]
): UseQueryResult<ApplicantAvailabilitiesResponse[], Error> =>
  useQuery({
    queryKey: ['applicants', 'availabilities', applicantIds],
    queryFn: async () =>
      await fetchApiWithToken<ApplicantAvailabilitiesResponse[]>({
        method: 'get',
        url: `/v1/applicants/availabilities?id[]=${applicantIds.join('&id[]=')}&itemsPerPage=1000`
      }),
    enabled: !!applicantIds.length
  })

export type ApplicantPicturesResponse = {
  profilePicUrl: string
  applicant: string
}

export const useApplicantPictures = (
  applicantIds: number[]
): UseQueryResult<ApplicantPicturesResponse[], Error> =>
  useQuery({
    queryKey: ['applicants', 'profile_pics', applicantIds],
    queryFn: async () =>
      await fetchApiWithToken<ApplicantPicturesResponse[]>({
        method: 'get',
        url: `/v1/applicants/profile_pics?id[]=${applicantIds.join('&id[]=')}&itemsPerPage=1000`
      }),
    enabled: !!applicantIds.length
  })

export const useApplicant = ({
  id
}: { id?: number }): UseQueryResult<ApplicantInfosResponse, Error> => {
  return useQuery({
    queryKey: ['applicants', id],
    queryFn: async () =>
      await fetchApiWithToken<ApplicantInfosResponse>({
        method: 'get',
        url: `/v1/applicants/${id}`
      }),
    enabled: !!id
  })
}

type ApplicantResumesResponse = {
  resumeData: {
    attachmentId: number
    applicantId: number
    icon: string
    link?: string
    hasPreview: boolean
    base64Content?: string
  }
}

export const useApplicantResumes = ({
  ids,
  withBase64
}: { ids: number[], withBase64: boolean }): UseQueryResult<ApplicantResumesResponse[], Error> => {
  return useQuery({
    queryKey: ['applicants', 'resumes', ids],
    queryFn: async () =>
      await fetchApiWithToken<string[]>({
        method: 'get',
        url: `/v1/applicants/resumes?id=${ids.join('&id[]=')}${withBase64 ? '&withBase64=1' : ''}`
      })
  })
}

type ApplicantResumesPreviewsResponse = {
  resumeName: string
  base64Content: string
}

export const useApplicantPreviewsResumes = ({
  ids
}: { ids: number[] }): UseQueryResult<
  ApplicantResumesPreviewsResponse[],
  Error
> => {
  return useQuery({
    queryKey: ['applicants', 'resumes_previews', ids],
    queryFn: async () =>
      await fetchApiWithToken<string[]>({
        method: 'get',
        url: `/v1/applicants/resumes_previews?id=${ids.join('&id[]=')}`
      })
  })
}

export type ApplicantReportsResponse = {
  id: number
  title: string
  created: string
  modified: string
  interviewReportSections: {
    name: string
    content: string
  }[]
}

export type ApplicantReportsLDResponse = {
  'hydra:totalItems': number
  'hydra:member': ApplicantReportsResponse[]
}

export const useApplicantReports = ({
  applicantId
}: {
  applicantId: number
}): {
  applicantReports: ApplicantReportsResponse[] | undefined
  applicantReportsTotal: number | undefined
  isLoading: boolean
  fetchNextPage: () => void
  hasNextPage: boolean
  isFetchingNextPage: boolean
} => {
  const { data, isLoading, fetchNextPage, hasNextPage, isFetchingNextPage } =
    useInfiniteQuery<
      ApplicantReportsLDResponse,
      Error,
      InfiniteData<ApplicantReportsLDResponse>
    >({
      queryKey: ['applicant', applicantId, 'reports'],
      initialPageParam: 1,
      getNextPageParam: (lastPage, allPages) => {
        const currentPage = allPages.length
        if (lastPage['hydra:totalItems'] > currentPage * 30) {
          return currentPage + 1
        }
      },
      queryFn: async ({ pageParam }) => {
        return (await fetchApiWithToken({
          method: 'get',
          url: `/v1/applicants/${applicantId}/interview_reports?page=${pageParam}&order[created]=desc`,
          useLDJson: true
        })) as ApplicantReportsLDResponse
      },
      enabled: !!applicantId,
      staleTime: 60_000
    })
  return {
    applicantReports: data?.pages.flatMap((page) => page['hydra:member']),
    applicantReportsTotal: data?.pages[0]?.['hydra:totalItems'],
    isLoading,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage
  }
}

export type ApplicantHeadData = {
  id: number
  firstname: string
  lastname: string
}

export const useApplicantsHead = ({
  applicantIds
}: { applicantIds: number[] }): UseQueryResult<ApplicantHeadData[], Error> => {
  return useQuery({
    queryKey: ['applicants', 'head', applicantIds],
    queryFn: async () => {
      return await fetchApiWithToken<ApplicantHeadData[]>({
        method: 'get',
        url: `/v1/applicants?id[]=${applicantIds.join('&id[]=')}&itemsPerPage=1000&fields[]=id&fields[]=firstname&fields[]=lastname`
      })
    },
    enabled: !!applicantIds.length
  })
}
