import { faFileSlash, faTrash } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import clsx from 'clsx'
import DOMPurify from 'dompurify'
import { AnimatePresence, motion } from 'framer-motion'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import {
  type InterviewReportPayload,
  useInterviewReportDeleteMutation,
  useInterviewReportMutation
} from '~/api/mutations/applicant'
import {
  type ApplicantReportsResponse,
  useApplicantReports
} from '~/api/queries/applicant'
import { useConfig } from '~/api/queries/global'
import { Loader } from '~/components/Global/Elements/Loader/Loader'
import {
  reportItemLabel,
  reportItemSections,
  reportItemTitle,
  reportItemUpdateStatus,
  reportItemValue,
  reportsListingAdd,
  reportsListingHeader,
  reportsListingItem,
  reportsListingItemDate,
  reportsListingItemDelete,
  reportsListingItemNotEditing,
  reportsListingItemTitle, reportsListingLegacy,
  reportsListingNoResults,
  reportsListingNoResultsIcon,
  reportsListingNoResultsReset,
  reportsListingTitle, reportsListingTitleLegacy,
  reportsListingWrapper
} from '~/components/Scopes/Applicants/ApplicantModal/ApplicantReports.css'
import { themeClass } from '~/styles/theme.css'
import { confirm, formatRelativeTime } from '~/utils'

export const ApplicantReports = ({
  applicantId,
  legacyStyle
}: {
  applicantId: number
  legacyStyle?: boolean
}) => {
  const lastItemRef = useRef<HTMLDivElement>(null)
  const [currentEdit, setCurrentEdit] = useState<'new' | number | null>(null)
  const [lastCreatedId, setLastCreatedId] = useState<number | null>(null)
  useConfig({ name: 'interview_report.sections' })
  const {
    applicantReports,
    isLoading,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage
  } = useApplicantReports({
    applicantId
  })

  const endOfListReached = useCallback(() => {
    if (hasNextPage && !isFetchingNextPage) {
      fetchNextPage()
    }
  }, [hasNextPage, isFetchingNextPage, fetchNextPage])

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0]?.isIntersecting) {
          endOfListReached()
        }
      },
      { threshold: 0.5 }
    )
    if (lastItemRef.current) {
      observer.observe(lastItemRef.current)
    }
    return () => {
      if (lastItemRef.current) {
        observer.unobserve(lastItemRef.current)
      }
    }
  }, [endOfListReached])

  return (
    <>
      <div className={clsx(reportsListingHeader, themeClass)}>
        <div className={clsx(reportsListingTitle, legacyStyle ? reportsListingTitleLegacy : null)}>Comptes rendus</div>
        <button
          className={reportsListingAdd}
          type={'button'}
          onClick={() =>
            setCurrentEdit((current) => (current !== 'new' ? 'new' : null))
          }
        >
          + Ajouter
        </button>
      </div>
      <AnimatePresence>
        <motion.div
          className={clsx(themeClass, reportsListingWrapper, legacyStyle ? reportsListingLegacy : null)}
          variants={{
            hidden: { opacity: 0 },
            visible: { opacity: 1, transition: { staggerChildren: 0.1 } }
          }}
          initial="hidden"
          animate="visible"
        >
          {applicantReports?.length === 0 && currentEdit !== 'new' && (
            <div className={reportsListingNoResults}>
              <div className={reportsListingNoResultsIcon}>
                <FontAwesomeIcon icon={faFileSlash} />
              </div>
              Aucun compte rendu existant
              <span
                className={reportsListingNoResultsReset}
                onClick={() => {
                  setCurrentEdit('new')
                }}
              >
                Créer un compte rendu
              </span>
            </div>
          )}
          {currentEdit === 'new' && (
            <ApplicantReportItem
              showEdit={true}
              onChangeCurrentEdit={(newEdit: 'new' | null | number) => {
                if (currentEdit === 'new' && typeof newEdit === 'number') {
                  // In case of new report creation, catch new id to avoid duplicates in the list keys
                  setLastCreatedId(newEdit)
                } else {
                  setCurrentEdit(newEdit)
                  setLastCreatedId(null)
                }
              }}
              applicantId={applicantId}
            />
          )}
          {applicantReports?.filter(report => report.id !== lastCreatedId).map((report) => (
            <ApplicantReportItem
              key={report.id}
              data={report}
              showEdit={currentEdit === report.id}
              onChangeCurrentEdit={setCurrentEdit}
              applicantId={applicantId}
            />
          ))}
          {isLoading && <Loader />}
          <div ref={lastItemRef} />
        </motion.div>
      </AnimatePresence>
    </>
  )
}

const ApplicantReportItem = ({
  applicantId,
  data,
  showEdit = false,
  onChangeCurrentEdit
}: {
  applicantId: number
  data?: ApplicantReportsResponse
  showEdit?: boolean
  onChangeCurrentEdit: (value: 'new' | number | null) => void
}) => {
  const { data: defaultSections } = useConfig<
    {
      name: string
      value: string
    }[]
  >({
    name: 'interview_report.sections'
  })
  const updateMutation = useInterviewReportMutation(applicantId)
  const deleteMutation = useInterviewReportDeleteMutation(applicantId)
  const [currentId, setCurrentId] = useState<number | undefined>(data?.id)
  const [formData, setFormData] = useState({
    title: data?.title || '',
    sections: data?.interviewReportSections || []
  })
  const [showUpdateStatus, setShowUpdateStatus] = useState(false)
  const [isContentEditable, setIsContentEditable] = useState(false)
  const itemRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    // Init form data with default sections if no data
    if (!data && defaultSections && !formData.sections.length) {
      setFormData({
        title: '',
        sections: defaultSections.map(({ value }) => ({
          name: value,
          content: ''
        }))
      })
    }
  }, [data, defaultSections, formData.sections.length])

  useEffect(() => {
    const handleClickOutside = (e: MouseEvent) => {
      if (itemRef.current && !itemRef.current.contains(e.target as Node)) {
        setIsContentEditable(false)
      }
    }
    document.addEventListener('click', handleClickOutside)
    return () => {
      document.removeEventListener('click', handleClickOutside)
    }
  }, [])

  useEffect(() => {
    let timeout: NodeJS.Timeout | null = null
    setShowUpdateStatus(true)
    if (updateMutation.isSuccess) {
      timeout = setTimeout(() => {
        setShowUpdateStatus(false)
      }, 3000)
    }
    return () => {
      if (timeout) {
        clearTimeout(timeout)
      }
    }
  }, [updateMutation.isSuccess])

  if (!showEdit && !data?.created) {
    return null
  }

  if (!showEdit && data) {
    return (
      <div
        className={clsx(reportsListingItem, reportsListingItemNotEditing)}
        onClick={() => onChangeCurrentEdit(data.id)}
      >
        <div className={reportsListingItemTitle}>
          {data.title !== ''
            ? data.title
            : `Compte rendu du ${new Date(data.created).toLocaleDateString()}`}
        </div>
        <div className={reportsListingItemDate}>
          {formatRelativeTime(new Date(data.created))}
        </div>
        <button
          type="button"
          className={reportsListingItemDelete}
          onClick={async (e) => {
            e.stopPropagation()
            confirm(
              'Supprimer ce compte rendu ?',
              'Ce compte rendu sera définitivement supprimé.',
              'Supprimer'
            ).then((confirmed) => {
              if (!confirmed) return
              deleteMutation.mutate({ id: data.id })
            })
          }}
        >
          <FontAwesomeIcon icon={faTrash} />
        </button>
      </div>
    )
  }

  const updateReport = (newFormData: InterviewReportPayload) => {
    // Do not update/create if form is empty
    if (
      newFormData.title === '' &&
      newFormData.sections.every((s) => s.content === '')
    ) {
      return
    }
    // Do not update if form state is the same
    if (
      newFormData.title === formData.title &&
      formData.sections.every(
        (s) =>
          newFormData.sections.find((ns) => ns.name === s.name)?.content ===
          s.content
      )
    ) {
      return
    }
    setFormData(newFormData)

    // Do not update if mutation is pending and no currentId (avoid creating multiple reports)
    // Keep form data in state to update it further @todo ensure this is actually done further
    if (updateMutation.isPending && !currentId) {
      return
    }
    updateMutation.mutate(
      {
        id: currentId,
        ...newFormData
      },
      {
        onSuccess: ({ id }) => {
          setCurrentId(id)
          onChangeCurrentEdit(id)
        }
      }
    )
  }

  return (
    <motion.div
      variants={{
        initial: { opacity: 0, x: 40 },
        hidden: { opacity: 0, x: 40 },
        visible: { opacity: 1, x: 0 }
      }}
      className={reportsListingItem}
      ref={itemRef}
    >
      <div>
        <button
          type="button"
          className={reportsListingAdd}
          onClick={() => onChangeCurrentEdit(null)}
        >
          &lt; Retour
        </button>
      </div>
      <div
        contentEditable={isContentEditable}
        onClick={() => setIsContentEditable(true)}
        className={reportItemTitle}
        onBlur={(e) =>
          updateReport({
            ...formData,
            title: e.currentTarget.textContent || ''
          })
        }
        data-placeholder={'Saisir un titre'}
        suppressContentEditableWarning={true}
      >
        {formData?.title}
      </div>
      <div className={reportItemSections}>
        {formData.sections.map((section) => (
          <div key={section.name}>
            <span className={reportItemLabel}>{section.name}</span>
            <span
              contentEditable={isContentEditable}
              className={reportItemValue}
              data-placeholder={'Saisir...'}
              suppressContentEditableWarning={true}
              // biome-ignore lint/security/noDangerouslySetInnerHtml: <explanation>
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(section.content)
              }}
              onClick={() => setIsContentEditable(true)}
              onBlur={(e) => {
                const newSections = formData.sections.map((s) =>
                  s.name === section.name
                    ? {
                        ...s,
                        content: e.currentTarget.innerHTML || ''
                      }
                    : s
                )
                updateReport({
                  ...formData,
                  sections: newSections
                })
              }}
            />
          </div>
        ))}
      </div>
      <div className={reportItemUpdateStatus}>
        {updateMutation.isError && (
          <div>Erreur lors de la sauvegarde du compte rendu</div>
        )}
        {updateMutation.isPending && <div>Sauvegarde en cours...</div>}
        {updateMutation.isSuccess && showUpdateStatus && (
          <div>Compte rendu sauvegardé</div>
        )}
      </div>
    </motion.div>
  )
}
