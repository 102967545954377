import { type UseQueryResult, useQuery } from '@tanstack/react-query'
import { fetchApiWithToken } from '../utils'

export interface Taxonomy {
  slug: string | null
  localizedName: string
  uri: string
  isEditable: boolean
}

type TaxonomySlugWithColor = 'applicant_order_statuses'
export type TaxonomySlug = TaxonomySlugWithColor | 'unavailability_types'

export type TaxonomyItem = {
  id: number
  name: string
}

export type TaxonomyItemWithColor = TaxonomyItem & {
  color: string
}

const defaultStaleTime = 1000 * 60 * 60 * 24

export const languageLevels = [
  {
    id: 1,
    name: 'Maternelle'
  },
  {
    id: 2,
    name: 'Bilingue'
  },
  {
    id: 3,
    name: 'Courant'
  },
  {
    id: 4,
    name: 'Professionnel'
  },
  {
    id: 5,
    name: 'Scolaire'
  }
]

export const softwareLevels = [
  {
    id: 1,
    name: 'Notions'
  },
  {
    id: 2,
    name: 'Maîtrisé'
  },
  {
    id: 3,
    name: 'Intermédiaire'
  },
  {
    id: 4,
    name: 'Expert'
  }
]

export const useTaxonomyList = (): UseQueryResult<
  { items: Taxonomy[] },
  Error
> =>
  useQuery({
    queryKey: ['taxonomyList'],
    queryFn: async () =>
      await fetchApiWithToken({
        method: 'get',
        url: '/v1/taxonomies'
      }),
    staleTime: defaultStaleTime
  })

export function useTaxonomy(slug: 'applicant_order_statuses'): UseQueryResult<
  (TaxonomyItemWithColor & {
    specialStatus: string | null
  })[],
  Error
>
export function useTaxonomy(
  slug: TaxonomySlugWithColor
): UseQueryResult<TaxonomyItemWithColor[], Error>
export function useTaxonomy(
  slug: TaxonomySlug
): UseQueryResult<TaxonomyItem[], Error>
export function useTaxonomy(
  slug: TaxonomySlug
): UseQueryResult<(TaxonomyItem | TaxonomyItemWithColor)[], Error> {
  return useQuery({
    queryKey: ['taxonomy', slug],
    queryFn: () =>
      fetchApiWithToken({
        method: 'get',
        url: `/v1/taxonomies/${slug}`
      }),
    staleTime: defaultStaleTime
  })
}
