import { useContext } from 'react'
import { AsideActionContext } from '~/utils/contexts'
import { sideWrapper } from '~/components/Layout/AsideActionWrapper/AsideActionWrapper.css'
import { AnimatePresence, motion } from 'framer-motion'

export const AsideActionWrapper = () => {
  const { asideAction } = useContext(AsideActionContext)
  return (
    <AnimatePresence>
      {asideAction && (
        <motion.div
          className={sideWrapper}
          key="aside-action"
          initial={{ opacity: 0, flexBasis: '0' }}
          animate={{ opacity: 1, flexBasis: '500px' }}
          exit={{ opacity: 0, flexBasis: '0' }}
        >
          {asideAction}
        </motion.div>
      )}
    </AnimatePresence>
  )
}
