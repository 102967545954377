import { useMutation, useQueryClient } from '@tanstack/react-query'
import type { OrderApplicant, OrderInfosResponse } from '~/api/queries/order'
import { fetchApiWithToken } from '~/api/utils'
import { ToastType } from '~/components/Global/Interactions/Toast/Toast'
import { toast, toISOLocal } from '~/utils'

type OrderApplicantStatusPayload = {
  applicants: (OrderApplicant | number)[]
  status: number
  startDate?: Date
  endDate?: Date
  unavailabilityType?: number
}

export const useOrderApplicantStatusMutation = (orderId?: number) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: ({
      applicants,
      status,
      startDate,
      endDate,
      unavailabilityType
    }: OrderApplicantStatusPayload) =>
      fetchApiWithToken({
        method: 'post',
        url: `/v1/orders/${orderId}/applicant_status`,
        data: {
          applicants: applicants.map(
            (applicant) =>
              `/v1/applicants/${typeof applicant === 'object' ? applicant.id : applicant}`
          ),
          applicantOrderStatus: `/v1/taxonomies/applicant_order_statuses/${status}`,
          startDate: startDate ? toISOLocal(startDate) : null,
          endDate: endDate ? toISOLocal(endDate) : null,
          unavailabilityType: unavailabilityType
            ? `/v1/taxonomies/unavailability_types/${unavailabilityType}`
            : null
        }
      }),
    onMutate: async (payload) => {
      // Cancel the query for the order to avoid our optimistic update to be overwritten
      await queryClient.cancelQueries({ queryKey: ['order', orderId] })
      const previousOrder = queryClient.getQueryData<OrderInfosResponse>([
        'order',
        orderId
      ])
      if (previousOrder) {
        // Update status of applicants in the order's cache
        const updatedOrderApplicants = previousOrder.applicants.map(
          (applicantOrder) => {
            if (
              payload.applicants.find(
                (applicant) =>
                  (typeof applicant === 'object' ? applicant.id : applicant) ===
                  applicantOrder.applicant.id
              )
            ) {
              return {
                ...applicantOrder,
                modified: toISOLocal(new Date()),
                status: {
                  id: payload.status,
                  name: ''
                }
              }
            }
            return applicantOrder
          }
        )
        // Add new applicants (ie from non order source (matching, jobmail, etc.)) to the order's cache
        for (const applicant of payload.applicants) {
          const applicantOrder = updatedOrderApplicants.find(
            (applicantOrder) =>
              applicantOrder.applicant.id ===
              (typeof applicant === 'object' ? applicant.id : applicant)
          )
          if (!applicantOrder) {
            let applicantUpdt: OrderApplicant = {
              id: applicant as number,
              firstname: '',
              lastname: '',
              picture: undefined,
              availability: undefined
            }
            if (typeof applicant === 'object') {
              applicantUpdt = applicant as OrderApplicant
            }
            updatedOrderApplicants.push({
              applicant: applicantUpdt,
              created: toISOLocal(new Date()),
              modified: toISOLocal(new Date()),
              status: {
                id: payload.status,
                name: ''
              }
            })
          }
        }
        queryClient.setQueryData<OrderInfosResponse>(['order', orderId], {
          ...previousOrder,
          applicants: updatedOrderApplicants
        })
      }
      return { previousOrder }
    },
    onSettled: () => {
      // Clear the order list cache cause status counts have changed (@todo would be better to update order cache in the list)
      void queryClient.invalidateQueries({ queryKey: ['order'] })
      void queryClient.invalidateQueries({ queryKey: ['order', orderId] })
      void queryClient.invalidateQueries({
        queryKey: ['order', orderId, 'history']
      })
    },
    onError: (_, { applicants }, context) => {
      if (context?.previousOrder) {
        queryClient.setQueryData(['order', orderId], context.previousOrder)
      }
      toast(
        'Une erreur est survenue',
        `Une erreur est survenue lors de la mise à jour du statut ${applicants.length > 1 ? 'des candidats' : 'du candidat'}`,
        ToastType.Danger
      )
    }
  })
}
