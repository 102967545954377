import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/pro-solid-svg-icons'
import { closeWrapper } from './CloseTrigger.css'

export const CloseTrigger = ({ onClose }: { onClose: () => void }) => {
  return (
    <div className={closeWrapper} onClick={onClose}>
      <FontAwesomeIcon icon={faTimes} />
    </div>
  )
}
