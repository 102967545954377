import { createContext, type ReactNode } from 'react'
import type { UserInfosResponse } from '~/api/queries/user'
import type { ApplicantModalType } from '~/types/global';

export const AuthContext = createContext<UserInfosResponse | null>(null)

export const AsideActionContext = createContext<{
  asideAction: ReactNode | null
  setAsideAction: (asideAction: ReactNode | null) => void
}>({
  asideAction: null,
  setAsideAction: () => {}
})

export const ApplicantModalContext = createContext<{
  applicantCollection: ApplicantModalType[]
  setApplicantCollection: (applicants: ApplicantModalType[]) => void
  applicantCurrentId: number | null
  setApplicantCurrentId: (id: number | null) => void
}>({
  applicantCollection: [],
  setApplicantCollection: () => {},
  applicantCurrentId: null,
  setApplicantCurrentId: () => {}
})
