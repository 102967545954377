import {
  faChevronsLeft,
  faChevronsRight
} from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import clsx from 'clsx'
import type React from 'react'
import { useRef } from 'react'
import { type ReactElement, useEffect, useState } from 'react'
import { Shimmer } from '~/components/Global/Elements/Shimmer/Shimmer'
import { themeClass } from '~/styles/theme.css'
import Logo from '../../../../img/logo-square.svg'
import {
  extendTrigger,
  footer,
  footerExtended,
  footerLogo,
  placeholder,
  wrapper,
  wrapperExtended
} from './GlobalMenu.css'
import { GlobalMenuItem } from './GlobalMenuItem'
import type { MenuItemPrimary } from './menu'

interface GlobalMenuProps {
  menu?: MenuItemPrimary[]
  currentSection?: string
  legacyUrl?: string | null
  mainRef?: React.RefObject<HTMLDivElement>
}

export const GlobalMenu = ({
  menu,
  currentSection,
  legacyUrl,
  mainRef
}: GlobalMenuProps): ReactElement => {
  const lowerScreenSize = 1500
  const extendedMenuSize = 260
  const lowMainSize = lowerScreenSize - extendedMenuSize
  const [canExtend, setCanExtend] = useState<boolean>(
    window.innerWidth > lowerScreenSize
  )
  const [extendedMenu, setExtendedMenu] = useState<boolean>(
    localStorage.getItem('extendedMenu') !== 'false' && canExtend
  )

  useEffect(() => {
    const resizeObserver = new ResizeObserver(() => {
      if (mainRef?.current) {
        const autoExtend = localStorage.getItem('extendedMenu') !== 'false'
        const isExtentable =
          mainRef.current.offsetWidth > lowMainSize + extendedMenuSize
        const shouldAutoHide = mainRef.current.offsetWidth < lowMainSize
        setCanExtend(isExtentable)
        if (autoExtend) {
          if (shouldAutoHide) {
            setExtendedMenu(false)
          } else if (isExtentable) {
            setExtendedMenu(true)
          }
        }
      }
    })
    // On legacy we use window size
    const handleResize = () => {
      const screenEnoughWide = window.innerWidth > lowerScreenSize
      setCanExtend(screenEnoughWide)
      setExtendedMenu(
        localStorage.getItem('extendedMenu') !== 'false' && screenEnoughWide
      )
    }
    if (mainRef?.current) {
      resizeObserver.observe(mainRef.current)
    } else {
      window.addEventListener('resize', handleResize)
    }
    return () => {
      if (mainRef?.current) {
        resizeObserver.disconnect()
      } else {
        window.removeEventListener('resize', handleResize)
      }
    }
  }, [mainRef, lowMainSize])

  const toggleExtendedMenu = () => {
    setExtendedMenu(!extendedMenu)
    localStorage.setItem('extendedMenu', (!extendedMenu).toString())
  }

  return (
    <nav
      className={clsx(
        themeClass,
        wrapper,
        extendedMenu ? wrapperExtended : null
      )}
    >
      {menu?.map((item) => (
        <GlobalMenuItem
          key={item.id}
          menuItem={item}
          currentSection={currentSection ?? null}
          legacyUrl={legacyUrl}
          extendedMenu={extendedMenu}
        />
      ))}
      {menu === undefined &&
        [...new Array(8)].map((_, index) => (
          // biome-ignore lint/suspicious/noArrayIndexKey: Shimmer cant have unique key
          <div className={placeholder} key={index}>
            <Shimmer width={35} height={35} />
          </div>
        ))}
      <div className={clsx(footer, extendedMenu ? footerExtended : null)}>
        <img src={Logo} alt="" className={footerLogo} />
        {canExtend && (
          <span onClick={toggleExtendedMenu} className={extendTrigger}>
            <FontAwesomeIcon
              icon={extendedMenu ? faChevronsLeft : faChevronsRight}
            />
          </span>
        )}
      </div>
    </nav>
  )
}
