import { type UseQueryResult, useQuery } from '@tanstack/react-query'
import { fetchApiWithToken } from '../utils'

export interface ConfigResponse {
  name: string
  value: string | number | boolean | null
}

export const useConfig = <T = ConfigResponse>({
  name
}: { name: string }): UseQueryResult<T, Error> =>
  useQuery({
    queryKey: ['config', name],
    queryFn: async ({ signal }) =>
      await fetchApiWithToken<T[]>({
        method: 'get',
        url: `/v1/configuration?name=${name}`,
        signal
      }).then((value) => value[0]),
    staleTime: 1000 * 60 * 60 * 24
  })

export interface BrandInfosResponse {
  name: string
  slug: string
  searchEndpoint: string
  pusherKey: string | null
  legacyUrl: string
  appUrl: string
  logo?: string | null
  logoHeader?: string | null
}

export const useBrandInfos = (): UseQueryResult<BrandInfosResponse, Error> =>
  useQuery({
    queryKey: ['brandInfos'],
    queryFn: async () =>
      await fetchApiWithToken({
        method: 'get',
        url: '/v1/configuration/brand'
      }),
    staleTime: 1000 * 60 * 60 * 24
  })

export interface ConfigValue {
  name: string
  value: string | number | boolean | null
}

export const useConfigValues = (
  keys: string[]
): UseQueryResult<{ [key: string]: ConfigValue }, Error> => {
  const searchParams = new URLSearchParams()
  for (const key of keys) {
    searchParams.append('name[]', key)
  }
  return useQuery({
    queryKey: ['config', keys],
    queryFn: async () =>
      await fetchApiWithToken({
        method: 'get',
        url: `/v1/configuration?${searchParams.toString()}`
      }),
    staleTime: 1000 * 60 * 60
  })
}

type MessageSendingInfo = {
  service: string
  smsMax: number
  smsRemaining: number
  smsRemainingType: string
  stopSMSLength: number
  singleSMSLength: number
  multiSMSLength: number
  forbiddenChars: string
  replaceChars: string
}

type MessageSendingInfoParams = {
  agencyId?: number | null
}

export const useMessageSendingInfo = ({
  agencyId
}: MessageSendingInfoParams): UseQueryResult<MessageSendingInfo> =>
  useQuery({
    queryKey: ['messageSendingInfo', agencyId],
    queryFn: async ({ signal }) =>
      await fetchApiWithToken({
        method: 'get',
        signal,
        url: `/v1/configuration/message_sending_info${agencyId ? `?agencyId=${agencyId}` : ''}`
      }),
    staleTime: 1000 * 60 * 60
  })
