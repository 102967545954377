import { faChevronDown } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import clsx from 'clsx'
import { AnimatePresence, motion } from 'framer-motion'
import React, { type ReactElement, useEffect, useRef, useState } from 'react'
import {
  errorMessage,
  inputError,
  inputLabel,
  inputWrapper
} from '~/components/Form/Input/TextInput.css'
import type { InputInFormProps } from '~/components/Form/Input/input'
import {
  dropDownValueBox,
  openIcon,
  openIconOpen,
  optionItem,
  optionsWrapper,
  optionsWrapperLeft,
  optionsWrapperSmall,
  wrapper,
  wrapperInline
} from './Dropdown.css'
import {
  valueBox,
  valueBoxFullWidth,
  valueBoxInline,
  valueBoxPlaceholder,
  valueBoxSmall
} from './Input.css'

export interface DropdownProps extends InputInFormProps {
  name?: string
  options: Array<{ label: string; value: string }>
  defaultValue?: string | null
  onChange?: (value: string) => void
  label?: string
  small?: boolean
  fullWidth?: boolean
  placeholder?: string
  valueBoxClassName?: string
  error?: null | { message: string }
}

export const Dropdown = ({
  name,
  options,
  defaultValue,
  label,
  onChange,
  small = false,
  fullWidth = false,
  placeholder,
  valueBoxClassName,
  register,
  watch,
  setValue,
  error = null
}: DropdownProps): ReactElement => {
  const [valueItem, setValueItem] = useState<{
    label: string
    value: string
  } | null>(
    options.find((option) => {
      if (watch && name) {
        // If inside a form, get the value from the form state
        return option.value === watch(name)
      }
      return option.value === defaultValue
    }) ?? null
  )
  const [isOpen, setIsOpen] = useState(false)
  const [openLeft, setOpenLeft] = useState(false)
  const wrapperRef = useRef<HTMLDivElement | null>(null)
  const dropdownRef = useRef<HTMLDivElement | null>(null)

  if (register !== undefined && name !== undefined) {
    register(name)
  }

  useEffect(() => {
    setValueItem(
      options.find((option) => option.value === defaultValue) ?? null
    )
    if (setValue && name) {
      setValue(name, defaultValue)
    }
  }, [defaultValue, options, setValue, name])

  useEffect(() => {
    const handleClick = ({ target }: MouseEvent): void => {
      if (
        wrapperRef.current !== null &&
        target instanceof Node &&
        !wrapperRef.current?.contains(target)
      ) {
        setIsOpen(false)
      }
    }
    document.addEventListener('click', handleClick)
    return (): void => {
      document.removeEventListener('click', handleClick)
    }
  }, [])

  useEffect(() => {
    if (dropdownRef.current === null || !isOpen) {
      setOpenLeft(false)
      return
    }
    const { x, width } = dropdownRef.current.getBoundingClientRect()
    setOpenLeft(x + width > window.innerWidth)
  }, [isOpen])

  const DropdownEl = (
    <div
      className={clsx(wrapper, !label && !fullWidth ? wrapperInline : null)}
      ref={wrapperRef}
    >
      <input type="hidden" value={valueItem?.value ?? ''} name={name} />
      <div
        className={clsx(
          dropDownValueBox,
          valueBox,
          valueItem === null ? valueBoxPlaceholder : null,
          small ? valueBoxSmall : null,
          fullWidth ? valueBoxFullWidth : null,
          !label ? valueBoxInline : null,
          valueBoxClassName,
          error !== null && inputError
        )}
        onClick={() => setIsOpen(!isOpen)}
      >
        {valueItem === null ? placeholder : valueItem.label}
        <FontAwesomeIcon
          icon={faChevronDown}
          className={clsx(openIcon, isOpen ? openIconOpen : null)}
        />
      </div>
      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial={{ opacity: 0, y: -10 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -10 }}
            className={clsx(
              optionsWrapper,
              small ? optionsWrapperSmall : null,
              openLeft ? optionsWrapperLeft : null
            )}
            ref={dropdownRef}
            key={name}
          >
            {options.map((option) => (
              <div
                key={option.value}
                className={optionItem}
                onClick={() => {
                  setValueItem(option)
                  setIsOpen(false)
                  onChange?.(option.value)
                  if (setValue && name) {
                    setValue(name, option.value)
                  }
                }}
              >
                {option.label}
              </div>
            ))}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  )

  if (!label) {
    return DropdownEl
  }
  return (
    <div className={inputWrapper}>
      {label && (
        <label htmlFor={name} className={inputLabel} onClick={(e) => {
          e.stopPropagation()
          setIsOpen(!isOpen)
        }}>
          {label}
        </label>
      )}
      {DropdownEl}
      {error !== null && (
        <div className={errorMessage} role="alert">
          {error.message}
        </div>
      )}
    </div>
  )
}
