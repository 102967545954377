import { faArrowLeft, faArrowRight } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import clsx from 'clsx'
import React, { useState } from 'react'
import { useOrderApplicantStatusMutation } from '~/api/mutations/order'
import {
  useApplicant,
  useApplicantPictures,
  useApplicantsHead
} from '~/api/queries/applicant'
import { useBrandInfos } from '~/api/queries/global'
import type { OrderApplicant } from '~/api/queries/order'
import { useTaxonomy } from '~/api/queries/taxonomy'
import { Dropdown } from '~/components/Form'
import { StandardModal } from '~/components/Global/Design/Modal/StandardModal'
import { NameCircle } from '~/components/Global/Elements/NameCircle/NameCircle'
import { Shimmer } from '~/components/Global/Elements/Shimmer/Shimmer'
import { Button } from '~/components/Global/Interactions/Button'
import { ToastType } from '~/components/Global/Interactions/Toast/Toast'
import {
  applicantModalActions,
  applicantModalAside,
  applicantModalMain,
  applicantModalMainHeader,
  applicantModalName,
  applicantModalNavButton,
  applicantModalNavButtonRight,
  applicantModalNavName,
  applicantModalPicture,
  applicantModalRole,
  applicantModalTitle,
  applicantModalWrapper
} from '~/components/Scopes/Applicants/ApplicantModal/ApplicantModal.css'
import { ApplicantReports } from '~/components/Scopes/Applicants/ApplicantModal/ApplicantReports'
import { ApplicantResumeViewer } from '~/components/Scopes/Applicants/ApplicantModal/ApplicantResumeViewer'
import { acceptModal } from '~/components/Scopes/Orders/Modals/modals'
import type { ApplicantModalType } from '~/types/global'
import { toast } from '~/utils'

type ApplicantModalProps = {
  defaultApplicantId: number
  collection?: ApplicantModalType[]
  onClose: () => void
}

export const ApplicantModal = ({
  defaultApplicantId,
  collection,
  onClose
}: ApplicantModalProps) => {
  const [localCollection, setLocalCollection] = useState<ApplicantModalType[]>(
    collection ?? []
  )
  const [applicantId, setApplicantId] = useState(defaultApplicantId)
  const { data: applicant, isLoading } = useApplicant({ id: applicantId })
  const { data: applicantsPicture, isLoading: applicantsPictureLoading } =
    useApplicantPictures([applicantId])
  const { data: orderStatuses } = useTaxonomy('applicant_order_statuses')
  const { data: brandInfos } = useBrandInfos()

  const currentApplicant = localCollection?.find(
    (applicant) => applicant.id === applicantId
  )
  const currentOrderStatusId = currentApplicant?.order?.status
  const currentOrder = currentApplicant?.order

  const { mutate: updateApplicantStatusMutate } =
    useOrderApplicantStatusMutation(currentOrder?.id)
  const { data: collectionData } = useApplicantsHead({
    applicantIds: localCollection?.map(({ id }) => id) ?? []
  })

  const updateCurrentApplicantStatusInLocalCollection = (status: number) => {
    setLocalCollection((collection) => {
      return [
        ...collection.map((applicant) => {
          if (!applicant.order) {
            return applicant
          }
          if (applicant.id === currentApplicant?.id) {
            return {
              ...applicant,
              order: {
                ...applicant.order,
                status
              }
            }
          }
          return applicant
        })
      ]
    })
  }

  const changeOrderStatus = (toStatusId: number) => {
    if (!toStatusId || currentOrderStatusId === undefined || !currentOrder) {
      return
    }

    if (currentOrderStatusId === toStatusId) {
      return
    }

    if (
      orderStatuses?.find((status) => status.id === toStatusId)
        ?.specialStatus === 'accepted'
    ) {
      void acceptModal(
        currentOrder,
        [currentApplicant as OrderApplicant],
        toStatusId
      ).then((success) => {
        if (!success) {
          return
        }
        toast(
          'Candidat accepté',
          'Le candidat a été accepté sur la commande',
          ToastType.Success
        )
        updateCurrentApplicantStatusInLocalCollection(toStatusId)
      })
      return
    }
    updateCurrentApplicantStatusInLocalCollection(toStatusId)

    updateApplicantStatusMutate(
      {
        applicants: [currentApplicant as OrderApplicant],
        status: toStatusId
      },
      {
        onSuccess: () => {
          toast(
            'Statut mis à jour',
            'Le statut du candidat sur cette commande a été mis à jour avec succès',
            ToastType.Success
          )
        }
      }
    )
  }

  const previousApplicant =
    localCollection?.[
      localCollection?.findIndex(({ id }) => id === applicantId) - 1
    ]
  const nextApplicant =
    localCollection?.[
      localCollection?.findIndex(({ id }) => id === applicantId) + 1
    ]

  const ApplicantNavDetails = ({
    applicant: { id, firstname, lastname }
  }: { applicant: { id: number; firstname?: string; lastname?: string } }) => {
    const applicantData = collectionData?.find((data) => data.id === id)
    if (!applicantData || (!firstname && !lastname)) {
      return null
    }
    return (
      <div className={applicantModalNavName}>
        {firstname ?? applicantData.firstname}{' '}
        {lastname ?? applicantData.lastname}
      </div>
    )
  }

  let firstName = currentApplicant?.firstname ?? applicant?.firstname
  let lastName = currentApplicant?.lastname ?? applicant?.lastname
  const collectionApplicant = collectionData?.find(
    ({ id }) => id === applicantId
  )
  if (collectionApplicant) {
    firstName = collectionApplicant.firstname
    lastName = collectionApplicant.lastname
  }

  const profilePictureUrl = applicantsPicture?.[0]?.profilePicUrl
  return (
    <StandardModal onClose={onClose} open={true}>
      <section className={applicantModalWrapper}>
        <main className={applicantModalMain}>
          <div className={applicantModalMainHeader}>
            {(localCollection?.length ?? 0) > 1 && (
              <>
                {previousApplicant && (
                  <Button
                    onClick={() => setApplicantId(previousApplicant?.id)}
                    className={applicantModalNavButton}
                  >
                    <FontAwesomeIcon icon={faArrowLeft} />
                    <ApplicantNavDetails applicant={previousApplicant} />
                  </Button>
                )}
                {nextApplicant && (
                  <Button
                    onClick={() => setApplicantId(nextApplicant?.id)}
                    className={clsx(
                      applicantModalNavButton,
                      applicantModalNavButtonRight
                    )}
                  >
                    <FontAwesomeIcon icon={faArrowRight} />
                    <ApplicantNavDetails applicant={nextApplicant} />
                  </Button>
                )}
              </>
            )}
            <div>
              {profilePictureUrl && (
                <img
                  src={
                    profilePictureUrl.indexOf('/admin') !== -1
                      ? `${brandInfos?.legacyUrl}${profilePictureUrl}`
                      : profilePictureUrl
                  }
                  className={applicantModalPicture}
                  alt={`${applicant?.firstname} ${applicant?.lastname}`}
                  draggable={false}
                />
              )}
              {!profilePictureUrl &&
                !applicantsPictureLoading &&
                !isLoading && (
                  <NameCircle
                    name={`${applicant?.firstname} ${applicant?.lastname}`}
                    size={50}
                  />
                )}
              {(applicantsPictureLoading || isLoading) && (
                <Shimmer height={50} width={50} />
              )}
            </div>
            <div className={applicantModalTitle}>
              <h3 className={applicantModalName}>
                {isLoading && !currentApplicant && !firstName ? (
                  <Shimmer height={20} width={100} />
                ) : (
                  <a
                    href={`${brandInfos?.legacyUrl}/admin/candidats/edit/${applicantId}`}
                    target="_blank"
                    className={applicantModalName}
                    rel="noopener noreferrer"
                  >
                    {firstName} {lastName}
                  </a>
                )}
              </h3>
              <h4 className={applicantModalRole}>
                {isLoading ? (
                  <Shimmer height={12} width={100} />
                ) : (
                  applicant?.qualifications[0]?.name
                )}
              </h4>
            </div>
            <div className={applicantModalActions}>
              {currentOrder && (
                <Dropdown
                  options={
                    orderStatuses?.map((status) => ({
                      label: status.name,
                      value: String(status.id)
                    })) ?? []
                  }
                  placeholder="Changer le statut"
                  defaultValue={String(currentOrderStatusId)}
                  onChange={(value) => changeOrderStatus(Number(value))}
                />
              )}
            </div>
          </div>
          <ApplicantResumeViewer applicantId={applicantId} />
        </main>
        <aside className={applicantModalAside}>
          <ApplicantReports applicantId={applicantId} />
        </aside>
      </section>
    </StandardModal>
  )
}
