export const formatRelativeTime = (
  date: Date | number,
  minimalCutOff = 0
): string => {
  const timeMs = typeof date === 'number' ? date : date.getTime()
  const deltaSeconds = Math.round((timeMs - Date.now()) / 1000)

  const lang = 'fr-FR'

  const cutoffs = [
    60,
    3600,
    86400,
    86400 * 7,
    86400 * 30,
    86400 * 365,
    Number.POSITIVE_INFINITY
  ]

  const units: Intl.RelativeTimeFormatUnit[] = [
    'second',
    'minute',
    'hour',
    'day',
    'week',
    'month',
    'year'
  ]

  const cutoffThreshold: string[] = [
    "à l'instant",
    'cette dernière heure',
    "aujourd'hui",
    'cette semaine',
    'ce mois-ci',
    'cette année',
    'il y a longtemps'
  ]

  let unitIndex = cutoffs.findIndex((cutoff) => cutoff > Math.abs(deltaSeconds))

  if (unitIndex === -1 || unitIndex < minimalCutOff) {
    unitIndex = minimalCutOff
  }

  const divisor = cutoffs[unitIndex - 1] as number
  const unit = Math.floor(deltaSeconds / divisor)
  const rtf = new Intl.RelativeTimeFormat(lang, { numeric: 'auto' })
  if (unit >= -1) {
    return cutoffThreshold[unitIndex] as string
  }
  if (Number.isNaN(unit)) {
    return cutoffThreshold[unitIndex] as string
  }
  return rtf.format(unit, units[unitIndex] as Intl.RelativeTimeFormatUnit).replace('minutes', 'min')
}

export const toISOLocal = (d: Date) => {
  const z  = (n: number) =>  (`0${n}`).slice(-2);
  const zz = (n: number) => (`00${n}`).slice(-3);
  let off = d.getTimezoneOffset();
  const sign = off > 0 ? '-' : '+';
  off = Math.abs(off);

  return `${d.getFullYear()}-${z(d.getMonth()+1)}-${z(d.getDate())}T${z(d.getHours())}:${z(d.getMinutes())}:${z(d.getSeconds())}.${zz(d.getMilliseconds())}${sign}${z(off/60|0)}:${z(off%60)}`;
}
