import type { IconDefinition } from '@fortawesome/fontawesome-svg-core'
import {
  faFileImage,
  faFilePdf,
  faFileWord
} from '@fortawesome/free-solid-svg-icons'
import { faFile } from '@fortawesome/pro-regular-svg-icons'
import type { Resume as ResumeInterface } from './Resume.d'

export const getIconFromResume = ({
  icon
}: {
  icon?: ResumeInterface['icon']
}): IconDefinition => {
  let faIcon = faFile
  switch (icon) {
    case 'file-pdf':
      faIcon = faFilePdf
      break
    case 'file-word':
      faIcon = faFileWord
      break
    case 'file-image':
      faIcon = faFileImage
  }
  return faIcon
}
