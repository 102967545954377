import { faSearch } from '@fortawesome/pro-light-svg-icons'
import { faIndustry, faUser } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { instantMeiliSearch } from '@meilisearch/instant-meilisearch'
import { useQuery } from '@tanstack/react-query'
import 'instantsearch.css/themes/reset.css'
import 'instantsearch.css/themes/satellite.css'
import type { BaseHit, Hit, SearchClient } from 'instantsearch.js'
import React, {
  type PropsWithChildren,
  type ReactElement,
  useEffect,
  useState
} from 'react'
import {
  Highlight,
  InfiniteHits,
  InstantSearch,
  RefinementList,
  type RefinementListProps,
  SearchBox,
  useInstantSearch
} from 'react-instantsearch'
import { useBrandInfos } from '~/api/queries/global'
import { useUserSearchToken } from '~/api/queries/user'
import { Badge } from '~/components/Global/Blocks/Badge/Badge'
import { Modal } from '~/components/Global/Design/Modal/Modal'
import { Shimmer } from '~/components/Global/Elements/Shimmer/Shimmer'
import {
  highlightRoot,
  hightlight,
  hitItem,
  hitList,
  itemIcon,
  itemIdentity,
  itemTitle,
  itemType,
  itemWrapper,
  keyStyle,
  keysWrapper,
  noResult,
  position,
  refinementCheckbox,
  refinementCount,
  refinementLabel,
  refinementLabelText,
  refinementList,
  refinementSeleted,
  searchBox,
  searchForm,
  searchInput,
  searchPlaceholder
} from './GlobalSearch.css'

interface HitProps extends BaseHit {
  id: string
  uid: string
  type: string
  fullname: string
  phone: string | null
  email: string
  company?: string
}

const EmptyQueryBoundary = ({ children }: PropsWithChildren): ReactElement => {
  const { indexUiState } = useInstantSearch()

  if (indexUiState.query === '') {
    return <></>
  }

  return <>{children}</>
}

const NoResultsBoundary = ({
  children,
  fallback
}: PropsWithChildren<{ fallback: ReactElement }>): ReactElement => {
  const { results } = useInstantSearch()
  if (results.__isArtificial !== true && results.nbHits === 0) {
    return (
      <>
        {fallback}
        <div hidden>{children}</div>
      </>
    )
  }
  return children as ReactElement
}

const NoResults = (): ReactElement => {
  const { indexUiState } = useInstantSearch()
  if (indexUiState.query === '') {
    return <></>
  }
  return (
    <div className={noResult}>
      Aucun résultat pour <q>{indexUiState.query}</q>.
    </div>
  )
}

export const GlobalSearch = (): ReactElement => {
  const [open, setOpen] = useState(false)
  let brandData:
    | {
        searchEndpoint: string
        slug: string
        legacyUrl?: string
      }
    | undefined
    | null = null
  let searchToken: { token: string } | undefined
  if (window.JobConfig === undefined) {
    const { data: brandInfos } = useBrandInfos()
    brandData = brandInfos
    const { data } = useUserSearchToken(localStorage.getItem('id'))
    searchToken = data
  } else {
    brandData = {
      searchEndpoint: window.JobConfig.search_endpoint,
      slug: window.JobConfig.instance.computedName
    }
    searchToken = { token: window.JobConfig.search_token }
  }

  const healthCheck = useQuery({
    queryKey: ['globalsearch.healthcheck'],
    queryFn: async () => {
      if (brandData?.searchEndpoint === undefined) {
        throw new Error('Brand data not found')
      }
      return await fetch(`${brandData.searchEndpoint}/health`).then(
        async (response) => await response.json()
      )
    },
    enabled:
      brandData?.searchEndpoint !== undefined && searchToken !== undefined
  })

  const CustomHit = ({ hit }: { hit: Hit<HitProps> }): ReactElement => {
    const onClick = (): void => {
      let uri = ''
      if (hit.type === 'Candidat') {
        uri = `${brandData?.legacyUrl ?? ''}/admin/candidats/edit/${hit.id}`
      }
      if (hit.type === 'Contact') {
        uri = `${brandData?.legacyUrl ?? ''}/admin/contacts/modifier/${hit.id}`
      }

      if (uri !== '') {
        if (window.JobConfig?.instance.openNewTab === true) {
          window.open(uri, '_blank')
        } else {
          window.location.href = uri
        }
      }
    }

    return (
      <div onClick={onClick} key={hit.uid} className={itemWrapper}>
        <div className={itemIcon}>
          <FontAwesomeIcon
            icon={hit.type === 'Candidat' ? faUser : faIndustry}
            size="2x"
          />
        </div>
        <div className={itemIdentity}>
          {hit.type === 'Contact' && (
            <span className={itemType} title={hit.company}>
              <Highlight
                attribute="company"
                hit={hit}
                classNames={{ highlighted: hightlight, root: highlightRoot }}
              />
            </span>
          )}
          <div className={itemTitle} title={hit.fullname}>
            <Highlight
              attribute="fullname"
              hit={hit}
              classNames={{ highlighted: hightlight, root: highlightRoot }}
            />
          </div>
        </div>
        {hit.type === 'Candidat' && hit.status_name && (
          <Badge
            title={hit.status_name}
            textColor={hit.status_text_color}
            backgroundColor={hit.status_background_color}
          />
        )}
      </div>
    )
  }

  const transformItems: RefinementListProps['transformItems'] = (
    items,
    metadata
  ) => {
    const facetsResults = metadata?.results?._rawResults[0]?.facets?.type ?? {}
    return items.map((item) => ({
      ...item,
      label: `${item.label}${(facetsResults[item.value] ?? 0) > 1 ? 's' : ''}`
    }))
  }

  const handleGlobalShortCut = (e: KeyboardEvent): void => {
    if (e.ctrlKey && e.key === 'k') {
      e.preventDefault()
      setOpen(true)
    }
  }

  useEffect(() => {
    window.addEventListener('keydown', handleGlobalShortCut)
    return (): void => {
      window.removeEventListener('keydown', handleGlobalShortCut)
    }
  })

  if (brandData === undefined || searchToken === undefined)
    return <Shimmer width={300} height={40} />

  if (
    healthCheck.isError ||
    (healthCheck.data !== undefined && healthCheck.data.status !== 'available')
  )
    return <>Erreur : recherche indisponible</>

  const instanceName: string = brandData.slug.replace('.', '-')

  const { searchClient } = instantMeiliSearch(
    brandData.searchEndpoint,
    searchToken.token,
    {
      primaryKey: 'uid'
    }
  )

  return (
    <>
      <div className={searchPlaceholder} onClick={() => setOpen(true)}>
        <FontAwesomeIcon icon={faSearch} />
        Rechercher dans job explorer
        <span className={keysWrapper}>
          <kbd className={keyStyle}>Ctrl</kbd>
          <kbd className={keyStyle}>K</kbd>
        </span>
      </div>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        customPositionClass={position}
      >
        <div className={searchBox}>
          <InstantSearch
            indexName={`${instanceName}_items:updated_at:desc`}
            searchClient={searchClient as unknown as SearchClient}
          >
            <SearchBox
              autoFocus={true}
              classNames={{
                form: searchForm,
                input: searchInput
              }}
            />
            <NoResultsBoundary fallback={<NoResults />}>
              <EmptyQueryBoundary>
                <RefinementList
                  attribute="type"
                  classNames={{
                    list: refinementList,
                    label: refinementLabel,
                    labelText: refinementLabelText,
                    checkbox: refinementCheckbox,
                    count: refinementCount,
                    selectedItem: refinementSeleted
                  }}
                  sortBy={['name:asc']}
                  transformItems={transformItems}
                />
                <InfiniteHits<Hit<HitProps>>
                  showPrevious={false}
                  hitComponent={CustomHit}
                  classNames={{
                    list: hitList,
                    item: hitItem
                  }}
                  translations={{
                    showMoreButtonText: 'Charger plus de résultats',
                    showPreviousButtonText: 'Charger les résultats précédents'
                  }}
                />
              </EmptyQueryBoundary>
            </NoResultsBoundary>
          </InstantSearch>
        </div>
      </Modal>
    </>
  )
}
