import clsx from 'clsx'
import React, { type ReactElement } from 'react'
import { inner, wrapper } from './NameCircle.css'

interface NameCircleProps {
  name: string
  size?: number
}

export const NameCircle = ({
  name,
  size = 35
}: NameCircleProps): ReactElement => {
  const names = name.split(' ').slice(0, 2)
  let initials = ''
  if (names.length === 0) {
    initials = 'NR'
  } else {
    for (const name of names) {
      initials += name[0]?.toUpperCase() ?? ''
    }
  }

  return (
    <span className={clsx(wrapper)}>
      <span
        className={clsx(inner)}
        style={{
          height: size,
          minWidth: size,
          width: size
        }}
      >
        {initials}
      </span>
    </span>
  )
}
