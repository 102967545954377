import type { OrderApplicant } from '~/api/queries/order'

export const acceptModalRef = {
  current: async (
    order: {
      id: number
      applicantWantedCount: number
      applicantAcceptedCount: number
    },
    applicants: (OrderApplicant | number)[],
    statusId: number
  ) => true
}

export const acceptModal = (
  order: {
    id: number
    applicantWantedCount: number
    applicantAcceptedCount: number
  },
  applicants: (OrderApplicant | number)[],
  statusId: number
) => acceptModalRef.current(order, applicants, statusId)
